.faq-questions {
  max-width: 65%;
  margin: 0 auto;
  margin-bottom: 120px;
}

.question {
  padding: 22px;
  margin-bottom: 16px;
  background: var(--white);
  border-radius: 10px;
  border: 1px solid #e2e2e2;
}

.question-title {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 0px;
  color: var(--black);
  cursor: pointer;
}

.question p {
  color: var(--muted);
  font-size: 16px;
  margin: 0px;
  margin-top: 10px;
}

.question div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-btn {
  background: transparent;
  border: 0;
}

.faq-btn svg {
  color: var(--primary);
}


@media (max-width: 1080px) {
  .faq-questions {
    max-width: 75%;
  }
}

@media (max-width: 678px) {
  .faq-questions {
    max-width: 100%;
  }

  .question-title {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .page-head {
    h2 {
      font-size: 22px;
    }

    p {
      font-size: 14px !important;
    }

    .page-search {
      width: 90% !important;
    }

    margin-bottom: 45px !important;
  }

  .question {
    padding: 16px !important;
    margin-bottom: 10px !important;

  }

  .question-title {
    font-size: 16px !important;
  }

  .question p {
    font-size: 14px !important;

  }
}