.order-summary-page {
  .order-summary-title {
    margin: 60px 0px;
    font-size: 22px;
    font-weight: 600;
  }

  .order-summary {
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    gap: 60px;
    margin-bottom: 120px;
  }

  .order-summary-info {
    .order-summary-img {
      width: 100%;
      height: 220px;
      border-radius: 10px;
    }

    h3 {
      margin: 20px 0px;
      font-size: 24px;
      max-width: 30ch;
    }

    button {
      width: 100% !important;
      padding: 10px !important;
    }

    .downloads-info {
      margin-top: 90px;

      .download-desc {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;

        button {
          background: transparent;
          border: 1px solid;
          border-color: var(--muted);
          border-radius: 10px;
          width: 96px !important;
          height: 44px;
          padding: 0px 20px;
        }
      }

    }
  }

  .order-summary-options {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin: 20px 0px;

    span {
      width: 45%;
      color: var(--muted);
      font-size: 14px;
    }

    span img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .order-prices {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 16px;
    margin-top: 30px;

    >div {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      align-items: center;

      h5 {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 0px;
      }

      span {
        font-size: 20px;
        font-weight: 500;
        margin-right: 100px;
      }
    }
  }

  .order-form {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
  }

  .order-form span {
    border: 1px solid var(--black);
    color: var(--black);
    width: 48px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin-top: 15px;
  }

  .form-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d9d9d9;
    color: var(--black);
    border-radius: 10px;
    padding: 10px 15px;
    width: 100%;
    height: 90px;

    p {
      color: var(--muted);
      margin-bottom: 5px;
    }

    h4 {
      margin: 0px;
      font-size: 20px;
    }

    button {
      background: none;
      border: none;
      color: var(--primary);
      font-size: 20px;
      font-weight: 500;
    }
  }

  .order-summery-form {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    .input-group {
      input {
        padding: 12px;
        border-radius: 10px;

        &:focus {
          box-shadow: none;
          border-color: #d9d9d9;
        }
      }
    }
  }

  .order-terms {
    // margin-left: 75px;
    margin: 15px 0 0;
    display: inline-flex;
    gap: 10px;
    font-size: 18px;

    p {
      font-weight: 500;
    }
  }
}


@media screen and (max-width: 400px) {
  .order-summary-page .order-summary-options span{
    width: 65%;
  }
}

@media (max-width: 991px) {
  .order-summary {
    grid-template-columns: 1fr !important;
  }

  .order-summary-form {
    order: 2 !important;
  }

  .order-summary-info {
    order: 1 !important;
  }
}

@media screen and (min-width: 910px) {
  .order-summary-page .order-summary-options span{
    width: 65%;
  }
}

@media (max-width: 500px) {

  .form-info h4 {
    font-size: 18px !important;
  }

  .order-summery-form {

    grid-template-columns: 1fr !important;
  }

  .order-form {
    gap: 15px !important;
  }

  .order-summary-page .order-prices>div span {
    margin-right: 20px !important;
  }

  .form-info {
    button {
      font-size: 16px !important;
    }
  }
}