.profile-page {
  .title {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 45px;
  }

  .profile-tab-content {
    width: 70%;
    margin: 45px auto;
    height: 100%;

    .tab-title {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      padding: 0px 60px;

      .nav-item {
        a {
          padding: 0px;
          border-radius: 6px;
          margin-bottom: 20px;
          color: var(--black);
          cursor: pointer;
        }

        .nav-link.active {
          background: none;
          font-weight: 600;
          border-bottom: 3px solid !important;
          border-image-slice: 1 !important;
          border-image-source: linear-gradient(to right, var(--primary), var(--secondary)) !important;
        }

      }
    }
  }

  .profile-content {
    margin-bottom: 120px;
  }



  .profile-content-title {
    text-align: center;
    text-decoration: underline;
    font-size: 16px;
    margin-top: 30px;
  }

  .personal-info {
    .img-update {
      margin: 45px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 24px;

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }

      .button {
        padding: 6px 28px;
        font-weight: 500;
        display: block;
        margin: 0px;
      }

      .btn-delete {
        background: #E0F0FD !important;
        color: var(--primary);
      }


    }

    .personal-info-form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 30px;
      row-gap: 0px;

      .button {
        margin: 0;
        padding: 12px;
      }
    }
  }

  .address {
    .address-table {
      margin-top: 30px;
      width: 800px;

      tr {
        border-bottom: 1px solid rgb(213, 213, 213);
      }

      th,
      td {
        padding: 15px 0px;
        font-weight: 500;
      }
    }

    >div p {
      margin-top: 20px;
    }

    >div .button {
      width: 100%;
      padding: 12px;
      margin-top: 30px;
    }
  }

  Table {
    margin-top: 12px;
  }

 
  .email {
    .email-info {
      padding: 30px 160px;

      >div {
        input {
          margin-right: 20px;
        }

        label {
          font-weight: 500;
        }
      }

      p {
        margin-top: 8px;
        margin-left: 32px;
      }

      .button {
        width: 100%;
        padding: 12px;
      }
    }
  }

  .delete {
    max-width: 50%;
    margin: 0 auto;

    >div {
      margin-top: 45px;

      p {
        color: var(--muted);
      }

      input {
        padding: 10px;
        margin-top: 20px;
      }

      .button {
        width: 100%;
        padding: 12px;
        margin-top: 40px;
      }
    }
  }
}


// Responsive

@media screen and (max-width: 360px) {
  .profile-page .delete > div p {
    font-size: 14px;
  }

  .profile-page .profile-content-title {
    font-size: 15px;
    position: relative;
    top: 5px;
  }

  .profile-page .email .email-info .button {
    font-size: 11px;
  }
}

@media screen and (max-width: 40em) {
  Table Tbody Tr {
    margin-top: 12px;
  }
}

@media screen and (min-width: 540px) and (max-width:770px) {
  .profile-page .email .email-info{
  width: 60%;
  margin: 0 auto;
  }
}

@media screen and (min-width: 560px) and (max-width: 920px) {

  Table {
    margin-top: 12px;
  }

  Tbody, Td, Tfoot, Th, Thead, Tr {
    font-size: 10px;

  }
  .profile-page .address > div .button {
    font-size: 14px;
    padding: 5px;
  }
}
@media screen and (min-width: 920px) and (max-width: 1280px) {
  Table {
    margin-top: 12px;
  }
  Tbody, Td, Tfoot, Th, Thead, Tr {
    font-size: 12px;

  }
  .profile-page .address > div .button {
    font-size: 14px;
    padding: 5px;
  }
}

@media screen and (max-width: 770px) {
  .profile-page .delete {
     padding: 25px;;
  }
}
@media screen and (min-width: 810px) and (max-width: 1080px) {
  .profile-page .profile-tab-content {
     width: 90%;
  }
  .profile-page .profile-tab-content .tab-title {
    padding: 0px;
  }
}




@media (max-width:900px) {
  .email {
    .email-info {
      padding: 30px 100px !important;
    }
  }

  .delete {
    max-width: 80% !important;
  }

  // .profile-page .profile-tab-content .tab-title {
  //   justify-content: flex-start;
  //   gap: 16px;
  // }
}

@media (max-width:768px) {
  .profile-page .title {
    margin-top: 20px;
  }

  .profile-page .profile-tab-content .tab-title {
    display: inline-block;
    margin-bottom: 20px;
  }

  .profile-page .profile-tab-content {
    width: 85%;
  }

  .profile-page .profile-tab-content .tab-title {
    padding: 0px 30px;
  }

  .profile-page .personal-info .personal-info-form {
    grid-template-columns: 1fr;
  }

  //---//
  .email {
    .email-info {
      padding: 30px 30px !important;
    }
  }

  //---//
  .delete {
    max-width: 100% !important;
  }
}

.Upload-btn {
  border: none;
  background-image: linear-gradient(to right, var(--primary), var(--secondary), );
  color: var(--white);
  padding: 6px 20px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
}



@media (max-width:600px) {
  .personal-info {
    text-align: center;
  }

  .profile-page .personal-info .img-update {
    display: inline-block;
    margin-bottom: 20px;

    .Upload-btn {
      display: block;
    }

    img {
      margin-bottom: 20px;
    }

    .button {
      margin: 20px 0px;
    }
  }
}