.help-page {
  margin-bottom: 120px;

  .help-tab-content {
    margin-bottom: 60px;
  }

  .tab-title {
    display: block;
    background-color: #fff;
    width: 200px;
    height: 100%;

    .nav-item {
      a {
        padding: 0px;
        border-radius: 6px;
        margin-bottom: 20px;
        color: var(--muted);
        cursor: pointer;
      }

      .nav-link.active {
        background: transparent !important;
        color: var(--black);
        font-weight: 500;
      }
    }
  }

  .tab-content {
    padding: 0px 30px;
  }

  .help-content-info {
    >div {
      margin-bottom: 30px;

      p {
        color: var(--muted);
      }

      h2,
      h3 {
        margin-bottom: 15px;
      }

      h2 {
        font-weight: 600;
        font-size: 32px;
      }
    }
  }

  .help-form {
    max-width: 60%;
    margin: 0 auto;

    .form-info {
      text-align: center;

      h2 {
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 15px;
      }

      p {
        color: var(--muted);
      }
    }

    .form {
      padding: 30px 60px;
    }
  }
}

@media (max-width: 991px) {
  .help-page .tab-content {
    padding: 0px;
    margin-top: 20px;
  }

  .help-page .help-form {
    max-width: 100%;
  }
}

@media (max-width: 520px) {
  .help-page .help-form .form {
    padding: 30px 0px;
  }
}

@media (max-width: 480px) {
  .help-page .help-content-info>div p {
    font-size: 14px !important;
  }

  .help-page .help-content-info>div h2 {
    font-size: 22px !important;
  }

  .help-page .help-content-info>div h3 {
    font-size: 20px !important;
  }

  .help-page .help-form .form-info p {
    font-size: 14px !important;
  }

  .help-page .help-form .form-info h2 {
    font-size: 22px !important;
  }

  .contact-form>div label {
    font-size: 14px !important;
  }
}