.product-tabs-input,
.product-tabs-select {
  // background-color: var(--white);
  width: 290px;
  border-radius: 12px;
  padding: 2px 4px;
  border: 1px solid var(--muted);

  .remove-input-style {
    background: transparent;
    border: none;
    color: var(--black);
  }

  input {
    background: transparent;
    border: none;
    // color: var(--white);

    &:focus {
      border: none !important;
      box-shadow: none !important;
      background: transparent;
    }
  }
}

.head-info-product {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0px;

  h3 {
    font-size: 24px;
  }
}

.product-page-options {
  background-color: var(--white);
  height: fit-content;
  width: 100%;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 30px;
}

.option-box {
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 0px;

  h5 {
    font-size: 18px;
    margin-bottom: 16px;
  }
}

.selects-info {
  display: flex;
  justify-content: space-between;
}

.select-info-btn,
.select-info-btn-active {
  width: 45px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  border: #888888 solid 1px;
  border-radius: 10px;
  color: var(--black);
  padding: 6px 0px;
  font-weight: 500;
  background: transparent;
}

.select-info-btn-active {
  background-color: #e4f3ff;
  color: var(--primary);
  border: none;
}

.product-tabs-select .form-select {
  background-color: transparent !important;
  border: none !important;
  color: var(--muted);

  &:focus {
    border: none !important;
    box-shadow: none !important;
    background: transparent;
  }
}

.sqft-text {
  background-color: var(--black) !important;
  color: var(--white) !important;
  border-radius: 10px;
  padding: 4px 8px;
}

.product-head-icons {
  display: flex;
  gap: 10px;
  align-items: center;

  .icon-btn {
    color: var(--muted);
    border: none;
    background-color: transparent;
  }
}

.product-page-infos {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
  margin-bottom: 60px;
}

.product-houses {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  gap: 30px;
}

.product-house-card {
  background-color: var(--white);
  border-radius: 10px;
  width: auto;
  height: 460px;

  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
  overflow: auto;

  img {
    width: 100%;
    height: unset;
    max-height: 300px;
  }
}

.product-list-houses {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.product-house-list-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  align-items: center;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: auto;

  img {
    width: 100%;
    height: unset;
    max-height: 300px;
  }
}

.product-house-info {
  padding: 20px;

  h3 {
    font-size: 22px;
    margin-bottom: 15px;
  }
}

.product-house-options {
  display: flex;
  gap: 20px;

  >div {
    img {
      width: 14px;
      height: 14px;
      margin-right: 10px;
      margin-top: -2px;
    }

    span {
      color: var(--muted);
      font-weight: 300;
      font-size: 14px;
    }
  }
}

.product-house-details {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 20px;

  button {
    font-size: 10px;
  }

  h5 {
    color: var(--primary);
    margin: 0px;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    margin-top: 2px;
  }
}

.sb-checkbox {
  display: inline-block;
  font-size: 0;

  &__input {
    display: none;

    &:checked {
      +.sb-checkbox__label {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__label {
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      width: 18px;
      height: 18px;
      border: 2px solid;
      box-sizing: border-box;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;
      border-color: var(--primary);
    }

    &:after {
      content: "✓";
      color: var(--primary);
      font-family: "sans-serif";
      font-size: 12px;
      line-height: 16px;
      padding-top: 2px;
      text-align: center;
      width: 16px;
      height: 16px;
      display: block;
      border-radius: 2px;
      overflow: hidden;
      text-align: center;
      opacity: 0;
      transition: 0.2s opacity;
    }
  }
}

.product-tabs-select2,
.product-tabs-select3 {
  width: 200px;
  padding: 0px;
}

.product-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}

.product-tabs-filter {
  width: 120px;
}

.MuiSlider-thumb {
  height: 15px !important;
  width: 15px !important;
  margin-top: -7px !important;
  background-color: var(--white) !important;
  border: 2px solid var(--primary);
}

//  --------------------
// Product Details Page
//  --------------------

.back-btn {
  margin: 30px 0px;

  svg {
    margin-right: 10px;
  }

  a {
    text-decoration: none;
    color: var(--black);
  }
}

// .product-details-page {

.details-page-hero {
  display: flex;
  //align-items: center;
  gap: 60px;
  margin-bottom: 90px;

  .details-slides {
    width: 50%;
    border-radius: 10px;
    margin-bottom: 100px;
    border-radius: 10px;
  }

  .slick-list {
    border-radius: 10px;
  }

  .details-slide-img {
    height: 433px !important;
    border-radius: 10px;
    object-fit: cover;
  }

  .slick-dots {
    bottom: -100px !important;
    display: flex !important;
    gap: 30px;
    // justify-content: space-between;
  }

  .slick-dots li {
    width: 80px;
    height: 80px;
    margin: 0px !important;
  }

  .slick-next {
    right: 20px;
  }

  .slick-prev {
    left: 20px;
    z-index: 111;
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: 30px;
  }

  .details-info {
    width: 50%;

    .details-info-head {
      display: flex;
      justify-content: space-between;
      gap: 60px;
      margin-bottom: 30px;

      h2 {
        font-weight: 600;
      }

      svg {
        font-size: 20px;
      }
    }

    .details-info-head-icons {
      display: flex;
      gap: 20px;
      align-items: center;

      button {
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .details-info-options {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 20px;

      span img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .details-infos-price {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 100px;
      margin-top: 30px;
      flex-direction: column;

      .button-main-container{
        display: flex;
        gap: 10px;
      }

      ul {
        display: flex;
        list-style-type: none;
        padding: 0;
        align-items: center;
      }

      .price-title {
        font-size: 28px;
        color: var(--primary);
        margin: 0px;
      }

      .price-value {
        font-size: 24px;
        font-weight: 500;
        padding-left: 10px;

      }

      .price-title span {
        color: var(--black);
        margin-left: 10px;
        font-weight: 500;
      }
    }
  }
}

.designs-details-tabs {
  margin: 30px 0px;
  border: none;

  .nav-link {
    padding: 0px !important;
    background-color: transparent !important;
    font-weight: 400;
    border: none !important;
    border-radius: none !important;
    margin-right: 20px;
    color: var(--black) !important;
    padding-bottom: 4px !important;
  }

  .nav-link.active {
    font-weight: 700;
    border-bottom: 2px solid !important;
    border-image-slice: 1 !important;
    border-image-source: linear-gradient(to right,
        var(--primary),
        var(--secondary)) !important;
  }
}

.product-details-tables-info {
  margin-bottom: 40px;

  h3 {
    font-weight: 600;
  }

  p {
    color: var(--muted);
    display: block;
    margin: 20px 0px;
  }
}

.product-details-tables {
  width: 100%;
  max-width: 100%;
  display: flex;
}

.product-details-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 60px;
  height: fit-content;
  display: flex;

  tr {
    border-bottom: 1px solid rgb(223, 223, 223);

    th {
      font-weight: 600;
      padding: 20px 0px;
      width: 400px;
    }

    td {
      padding: 20px 0px;
      width: 400px;
    }
  }
}

.view-3d{
  .modal-dialog{
    top: 50%;
    transform: translateY(-50%) !important;
  }
  .modal-content{
    background-color: transparent;
    border: none;
    border-radius: 8px;
    overflow: hidden;
  }
}

.similar-properties-heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  h3 {
    text-decoration: none !important;
  }

  a {
    text-decoration: none;
    color: var(--black);
    font-weight: 600;
  }
}

.similar-properties-exploreAll {
  position: relative;
  top: 50%;
  transform: translateY(9%);
}

.similar-properties {
  padding-right: 0px !important;
  margin-bottom: 30px;
}

.similar-properties-slide {

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    padding: 20px 0px;
  }

  .slick-slide {
    width: 350px !important;
    margin-right: 20px;
  }

  .slick-dots {
    bottom: -100px !important;
    display: flex !important;
    justify-content: space-between;
  }

  .slick-dots li {
    width: 80px;
    height: 80px;
    margin: 0px !important;
  }

  .slick-next {
    right: 20px;
  }

  .slick-prev {
    left: 0px;
    z-index: 111;
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: 35px;
    color: var(--black);
  }
}

.details-page-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 30px;
  margin-bottom: 120px;

  a {
    border: 2px solid rgb(223, 223, 223);
    padding: 10px 14px;
    border-radius: 30px;
    text-decoration: none;
    color: var(--black);
  }
}

.option-icons-hide {
  display: none;
}

.option-hide-head-flex {
  display: flex;
  justify-content: space-between;
}

// Index Filter options
.filter-option-head {
  padding: 0px 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    font-size: 24px;
    cursor: pointer;
  }
}

.filter-option-button {
  display: flex;
  gap: 20px;
  padding: 0px 45px;
}

.filter-option-box {
  position: relative;
  width: 60%;
  margin: 0 auto;
  height: auto;
  max-height: 85vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: var(--white);
  border-radius: 10px;
  overflow: auto;

  .filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
    align-items: center;
    padding: 45px;
  }
}

//  --------------------
//  --------------------
//  --------------------
// Responsive
//  --------------------
//  --------------------
//  --------------------

@media (max-width: 480px) {
  // .option-icons-hide {
  //   display: block;
  // }

  // .option-icons-show {
  //   display: none;
  // }

  .option-hide-head {
    display: flex !important;
    justify-content: space-between;
    margin-top: 26px;
  }

  .product-head-icons {
    width: 100%;
    justify-content: space-between;
  }

  .product-tabs-select {
    width: 160px !important;
  }

  .product-tabs-select2 {
    width: 160px !important;
  }

  .product-tabs-select3 {
    width: 143px !important;
  }

  .product-tabs-input {
    width: 160px !important;
  }

  .resBtn {
    width: 160px !important;
  }

  .slick-dots li {
    width: 60px !important;
    height: 60px !important;
  }

  .details-infos-price {
    display: block !important;

    h5 {
      font-size: 24px !important;
    }

    .price-button {
      width: 100%;
      margin-top: 30px !important;
      margin-bottom: 10px !important;
      padding: 10px auto;
    }

    .option-hide-head-flex {

      h4 {
        margin-top: 2px;
      }
    }
  }

  .details-info-options {
    span img {
      width: 15px !important;
      height: 15px !important;
    }
  }
}

@media (min-width: 1200px) {
  .option-hide-head-flex {
    h4 {
      margin-top: 4px;
    }
  }
}

@media (max-width: 1280px),
(min-width: 960px) {
  .details-page-hero .details-info .details-infos-price {
    gap: 0px;
  }

  .details-infos-price {
    button {
      margin-bottom: 8px !important;
      font-size: 14px;
      margin-left: 20px;
      margin-top: 0px !important;
      padding: 6px 35px !important;
      max-width: 220px;
    }
  }
}

@media (max-width: 1220px) {
  .product-houses {
    grid-template-columns: 1fr;
  }

  .product-house-list-card {
    grid-template-columns: 1fr;
  }

  .product-house-info {
    h3 {
      font-size: 18px;
    }
  }

  .view-change-btn {
    display: none;
  }

  .filter-option-box {
    width: 90% !important;
  }
}

@media screen and (min-width: 465px) and (max-width: 960px) {
  .details-page-hero .details-info .details-infos-price {
    margin-top: 30px !important;
  }
}

@media (max-width: 960px) {
  .details-page-hero {
    display: block !important;
    margin-bottom: 60px !important;
  }

  .details-slides {
    width: 100% !important;
  }

  .details-info {
    width: 100% !important;
    //margin-top: 60px;
    display: inline-block;
  }

  .details-info-orders {
    .details-info-head {
      order: 1;
      margin-top: 30px;
    }

    .details-infos-price {
      order: 2;
      margin-bottom: 30px;
      margin-top: 0px !important;

      button {
        margin-bottom: 7px !important;
        font-size: 18px;
      }
    }

    .details-infos-lastorder {
      order: 3;
    }
  }

  .filter-option-box {
    .filters {
      grid-template-columns: 1fr;
      padding: 15px;
      overflow-x: auto;
    }

    .filter-option-head,
    .filter-option-button {
      padding: 0px 15px;
    }
  }

  .option-hide-head-flex {
    h4 {
      margin-top: 6px;
    }
  }
}

@media (max-width: 898px) {
  .product-details-table {
    display: block !important;

    tr th {
      width: 700px !important;
    }
  }

  .hide-sm {
    display: none;
  }

  .product-page-infos {
    grid-template-columns: 1fr;
  }

  .product-filter {
    width: 135px !important;

    .btn {
      font-size: 15px !important;
      color: var(--muted) !important;
    }

    .show>.btn-primary.dropdown-toggle {
      background: transparent !important;
      color: var(--muted);
      width: 130px;
      overflow: hidden;
      border: none !important;
    }

    .btn-primary:hover {
      color: var(--muted);
      background: transparent !important;
      border: none !important;
    }

    .btn-primary:active:focus,
    .show>.btn-primary.dropdown-toggle:focus,
    .btn-primary:focus {
      box-shadow: none !important;
    }

    .btn-primary.dropdown-toggle {
      background: transparent !important;
      border: none !important;
    }

    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-menu {
      padding: 0px;
      margin-top: 30px;
      border-radius: 10px;
      width: 340px;
      margin-left: -5px;
    }
  }
}

@media (max-width: 480px) {
  .small-font{
    font-size: 14px;
  }
  .head-info-product h3 {
    font-size: 18px;
  }

  .product-form {
    gap: 30px;
  }

  .product-tabs-input .remove-input-style,
  .product-tabs-select .remove-input-style {
    font-size: 14px;
    padding-left: 6px !important;
  }

  .input-group>.form-control,
  .input-group>.form-select {
    font-size: 14px;
    padding-left: 6px;
  }

  .details-page-hero .details-slide-img {
    height: 250px;
    border-radius: 10px;
  }
}

@media (max-width: 360px) {
  .product-tabs-select3 {
    width: unset !important;
  }
}

@media (max-width: 370px) {
  .product-house-details h5 {
    font-size: 15px !important;
  }

  .product-house-details .button {
    font-size: 6px !important;
    padding: 6px 18px !important;
  }

  .product-house-card {
    height: 330px;
  }
}

@media (min-width: 897px) and (max-width: 10000px) {
  .hide-xl {
    display: none;
  }
}

@media screen and (min-width: 965px) and (max-width: 1800px) {
  .details-page-hero .details-info .details-infos-price {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: inherit;
  }
}

.consultation {
  .modal-header {
    border-bottom: 0;
  }

  .modal-body {
    .input-text {
      input {
        padding: 14px 14px 14px 14px;
      }
    }

    select {
      padding: 14px 14px 14px 14px;
    }
  }

  .consultation-button {
    padding: 14px 120px 14px 120px;
  }
}

.complete-list {
  font-weight: bold;
  color: #999999;
  text-align: center;
  margin-bottom: 20px;
}