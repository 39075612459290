.footer {
	background-color: var(--black);
	color: var(--white);

	ul {
		padding: 0;
	}

	ul>li {
		list-style: none;

		>a {
			text-decoration: none;
			color: var(--white);
		}
	}


	.footer-wrapper {
		display: grid;
		grid-template-columns: 2fr 1fr 1fr 1.5fr;
		gap: 30px;
		flex-wrap: wrap;
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.footer-logo {
		img {
			width: 300px;
			height: 80px;
		}

		p {
			max-width: 35ch;
			margin: 20px 0px;
		}

		ul {
			display: flex;
			gap: 10px;

			li {
				border: 1px solid var(--white);
				padding: 4px 8px;
				border-radius: 50%;
			}
		}

		.socialmedia_title {
			font-size: 16px;
		}
	}

	.footer-links {
		h3 {
			font-weight: 500;
			font-size: 26px;
			margin-bottom: 30px;
		}

		ul>li {
			cursor: pointer;
			margin: 20px 0px;
			max-width: 30ch;
		}
	}

	.footer-contacts {
		ul>li {
			display: flex;
		}

		svg {
			margin-right: 7px;
		}

		span {
			display: block;
			font-size: 16px;
		}
	}

	.footer-bottom {
		background-color: var(--white);
		color: black;
		text-align: center;
		padding: 15px 0px;

		p {
			margin-bottom: 0;

		}
	}
}

@media (max-width: 1280px) {
	.footer .footer-links h3 {
		font-size: 22px !important;
	}
}

@media (max-width:1106px) {
	.footer .footer-wrapper {
		grid-template-columns: 1fr 1fr;
	}
}

@media (max-width:580px) {
	.footer .footer-wrapper {
		grid-template-columns: 1fr;
		justify-content: center;
		text-align: center;
	}

	.footer .footer-logo p {
		margin: 30px auto;
	}

	.footer .footer-logo ul {
		justify-content: center;
	}

	.footer .footer-links {
		ul>li {

			max-width: 100%;
		}
	}

	.footer .footer-contacts {
		ul>li {
			justify-content: center;
			max-width: 30ch;
			margin: 30px auto;
		}
	}

	.footer .footer-contacts svg :last-child {
		margin-right: 0px;
	}
}