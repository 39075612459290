//  --------------------
//  --------------------
// About
//  --------------------
//  --------------------

.about-bg {
  width: 100%;
  max-width: 100%;
  height: 450px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  margin-top: -40px;
}

.about-info {
  margin-left: 100px;
  margin-bottom: 50px;

  h2 {
    background-color: var(--black);
    padding: 10px 20px;
    display: inline-block;
    margin-bottom: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: var(--white);
    font-weight: 700;
    font-size: 24px;
  }

  >div {
    padding: 10px 0px;
    background-color: var(--black);
    width: 55%;
    color: var(--white);
    padding: 10px 20px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    button {
      background: transparent;
      border: none;
      color: var(--white);
      font-weight: 700;

      svg {
        margin-right: 10px;
      }

    }
  }
}

.about-cards {
  margin-bottom: 120px;
}

.about-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;
  margin-top: 60px;

  >div h3 {
    max-width: 18ch;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  >div p {
    max-width: 60ch;
    color: var(--muted);
    font-size: 16px;
    padding-right: 30px;
  }
}

.about-img {
  width: 100%;
  max-width: 100%;
  height: unset;
}

.right-image {
  >div {
    padding-left: 60px;
    order: 1;

    p {
      padding-right: 0px !important;
    }
  }

  img {
    order: 2;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.left-image {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

//  --------------------
//  --------------------
// Contact
//  --------------------
//  --------------------

.contact-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 120px;
  width: 75%;
  max-width: 75%;
  margin: 0 auto;
  margin-bottom: 90px;
}

.contact-info {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 45px 0px;

  >div img {
    width: 50px;
    height: unset;
  }

  >div p {
    margin: 0px;
    max-width: 30ch;
  }
}

.contact-form {
  >div label {
    font-weight: 500;
  }

  >div input,
  >div select {
    height: 50px;
  }

  >div select {
    color: #6c757d;
  }

  >div input,
  >div select,
  textarea {
    margin-bottom: 20px;
    border-color: rgb(170, 170, 170);
    border-radius: 10px;

    &:focus {
      border-color: rgb(170, 170, 170);
      box-shadow: none !important;
      background: transparent;

    }
  }
}

.form-btn {
  width: 100%;
  padding: 10px 0px !important;
}

.sent-text {
  margin-top: 10px;
  font-size: 14px;
}


//  --------------------
//  --------------------
// Responsive
//  --------------------
//  --------------------

@media (max-width: 1220px) {
  .contact-details {
    width: 100%;
    max-width: 100%;
  }
}


@media (max-width: 960px) {
  .contact-details {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .about-info {
    >div {
      width: 70%;
    }
  }

  .contact-details {
    display: grid;
    margin-bottom: 30px;

    .contact-info-wrapper {
      order: 2;

      h3 {
        margin-top: 30px;
        text-align: center;
      }
    }

    .contact-info {
      display: block;
      text-align: center;

      >div img {
        margin-bottom: 20px;
      }

      >div p {
        margin: 0px auto;
      }
    }

    .contact-form {
      order: 1;
    }
  }
}

@media (max-width: 720px) {
  .about-info {
    margin-left: 30px;

    >div {
      width: 90%;
    }
  }

  .about-card {
    grid-template-columns: 1fr;
    margin-right: 20px;

    img {
      order: 2;
    }

    >div {
      padding: 0px 20px;
      order: 1;

      p {
        max-width: 100%;
        padding: 0px;
      }

    }
  }

  .right-image {
    margin-right: 0px !important;
    margin-left: 20px;

    >div {
      padding: 0px 20px;
    }
  }
}

@media (max-width: 720px) {
  .page-head h2 {
    font-size: 22px !important;
    margin-top: 4px !important;
  }
}