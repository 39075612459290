.my-designs-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 120px;
}

.my-designs-title {
  margin-top: 30px;
  margin-bottom: 45px;
  display: inline-block;
}

.product-card {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
  overflow: auto;

  img {
    width: 100%;
    height: auto;
  }

  >div {
    padding: 20px;

    h3 {
      font-size: 20px;
      margin: 0px;
    }

    .button {
      padding: 8px 20px;
      font-size: 16px;
      margin-top: 13px;
    }
  }
}
.download-design {
  >div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 20px 0px;

    h3 {
      margin-bottom: 0px;
      font-size: 22px;
    }

    button {
      margin: 0px;

      svg {
        margin-right: 10px;
      }
    }
  }
}

.my-designs-tabs {
  margin-bottom: 30px;
  border: none;
  font-size: 20px;

  .nav-link {
    padding: 0px !important;
    background-color: transparent !important;
    font-weight: 400;
    border: none !important;
    border-radius: none !important;
    margin-right: 30px;
    color: var(--black) !important;
    padding-bottom: 4px !important;
  }

  .nav-link.active {
    font-weight: 700;
    border-bottom: 2px solid !important;
    border-image-slice: 1 !important;
    border-image-source: linear-gradient(to right, var(--primary), var(--secondary)) !important;
  }
}

.mydesign-btn {
  padding: 10px 30px !important;
  margin-top: 30px !important;
}


.get-consultation-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 11111111111111;
  text-align: left;

  .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 75vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: var(--white);
    border-radius: 10px;
    padding: 60px;
    overflow: auto;

    .box-info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      svg {
        font-size: 24px;
        cursor: pointer;
      }
    }

    button {
      margin-bottom: 0px;
      width: 50%;
      padding: 16px 0px;
    }

    .textarea {
      grid-column: 1/3;
      margin-top: 10px;

    }

    .mydesign-popup-text {
      margin-top: 30px;
      width: 50%;

      a {
        font-weight: 600;
      }
    }
  }

  .consultation-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
  }
}

.select-room-option {
  .css-1s2u09g-control {
    border-color: rgb(170, 170, 170);
    border-radius: 10px;
    padding: 6px;
  }
}

.select-options {
  width: 100%;
  margin-bottom: 30px;
  position: relative;

  .head {
    cursor: pointer;

    span {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      padding: 6px 12px;
      background: transparent;
      color: #6c757d;
      border: 1px solid var(--muted);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .options {
    background-color: var(--white);
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #999;
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    z-index: 11111;

    >div {
      padding: 4px 0px;
      display: flex;
      align-items: center;

      input {
        margin: 0;
        height: 30px;
      }

      label {
        font-weight: 500;
        margin-left: 12px;
      }
    }

    .add-new-btns {
      button {
        padding: 6px;
        width: 120px;
      }

      .btn-clear {
        background: #ddf0ff;
        color: var(--primary);
      }
    }
  }
}

@media (max-width:1080px) {
  .my-designs-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .get-consultation-box .box {
    width: 90% !important;
  }
}

@media (max-width:620px) {
  .my-designs-cards {
    grid-template-columns: repeat(1, 1fr);
  }

  .my-designs-title {
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .get-consultation-box {
    .box {
      width: 90%;
      padding: 30px;

      button {
        width: 100%;
      }

      .textarea {
        grid-column: 1/1;
      }

      .mydesign-popup-text {
        width: 100%;
      }

      .consultation-form {
        grid-template-columns: 1fr;
      }
    }
  }
}