.nav-link {
	color: var(--black);
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

.tabs-section {
	margin-top: -150px;
	z-index: 111;
	position: relative;
}

.tabs-info {
	background-color: var(--white);
	width: 100%;
	padding: 30px;
	height: fit-content;
	border-radius: 20px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.tabs-details {
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	padding: 0;

	li {
		border-radius: 12px;
		border: 1px solid var(--muted);
		padding: 10px 16px;
		list-style: none;

		.filter-icon {
			margin-left: 0px;
			margin-right: 10px;
		}

		svg {
			margin-left: 15px;
			font-size: 14px;
		}
	}
}

.tabs-form-input,
.tabs-form-select {
	background-color: #D9D9D960;
	width: 300px;
	border-radius: 12px;
	padding: 4px;

	span {
		background: transparent;
		border: none;
		color: var(--muted);
	}

	input {
		background: transparent;
		color: var(--muted);
		border: none;

		&:focus {
			border: none !important;
			box-shadow: none !important;
			background: transparent;

		}
	}
}

.tabs-form-select .form-select {
	background-color: transparent !important;
	border: none !important;
	color: var(--muted);

	&:focus {
		border: none !important;
		box-shadow: none !important;
		background: transparent;

	}
}

.hero-section {
	background-image: linear-gradient(to right, #E0F0FD, #F0F8FF, var(--white));
	width: 100%;
	max-width: 100%;
	height: fit-content;
	padding-top: 30px;
	margin-top: -40px;
}

.hero-wrap {
	display: flex;
	justify-content: space-between;
	padding-left: 100px;
	align-items: center;
	gap: 50px;
}

.hero-info {
	h2 {
		font-size: 40px;
		font-weight: 600 !important;
	}

	p {
		color: var(--muted);
		margin-top: 20px;
		max-width: 50ch;
	}
}

.hero-img {
	img {
		width: auto;
		height: 600px;
	}
}


.section-images {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 30px;
	margin-top: 40px;
}

.services {
	display: grid;
	justify-content: space-between;
	grid-template-columns: repeat(4, 1fr);
	gap: 30px;
	margin-top: 40px;
}

.services-wrap {

	h3 {
		font-size: 22px;
		font-weight: 600;
	}

	p {
		color: var(--muted);
	}
}

.house-design-card {
	background-color: var(--white);
	border-radius: 10px;
	box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
	overflow: auto;
	//width: 30vw;

	img {
		width: 100%;
		height: 250px;
	}
}

.design-card-info {
	padding: 20px;

	h3 {
		font-size: 18px;
	}

	button {
		font-weight: 600 !important;
	}
}

.design-card-options {
	display: flex;
	gap: 20px;

	>div {
		display: flex;
		align-items: center;
	}

	>div span {
		font-size: 14px;
		color: var(--muted);
	}

	>div img {
		width: 14px;
		height: 14px;
		margin-right: 10px;
	}
}


.our-services {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 60px;

	img {
		width: auto;
		height: 370px;
	}
}

.our-services-list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
	margin-top: 30px;
	margin-bottom: 10px;

	>div {
		display: flex;
		align-items: center;
		gap: 30px;

		span {
			font-weight: 600
		}
	}
}

.our-service-ball {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: rgba(10, 130, 223, 60%);
}

.service-hr {
	background-color: rgba(10, 130, 223, 80%);
	width: 90px;
	height: 3px;
	border-radius: 30px;
	margin: 15px 0px;
}

.service-num {
	font-size: 72px;
	font-weight: 700;
}

.services-wrap:nth-child(1) .service-num {
	color: rgba(10, 130, 223, 100%);
}

.services-wrap:nth-child(2) .service-num {
	color: rgba(10, 130, 223, 80%);
}

.services-wrap:nth-child(3) .service-num {
	color: rgba(10, 130, 223, 60%);
}

.services-wrap:nth-child(4) .service-num {
	color: rgba(10, 130, 223, 40%);
}

.overlay-image {
	position: relative;

	img {
		width: 100%;
    	height: 18vw;
		border-radius: 10px;
		box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
	}

	.overlay-image-title {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		color: var(--white);
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		opacity: 0;
	}

	&:hover {
		.overlay-image-title {
			opacity: 1;
		}
	}
	
}

.site-wrapper {
	padding: 0px 100px;
	margin-top: 100px;
}
.min-height-400 {
	min-height: 400px;
}


.index-section {
	padding-top: 0px;
	padding-bottom: 0px;
}

.interior-section-top {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	a {
		text-decoration: none;
		color: var(--black);
		font-weight: 600;
	}
}

.section-top {
	span {
		font-size: 16px;
		margin-bottom: 8px;
		display: block;
	}

	h3 {
		font-size: 32px;
		font-weight: 500;
		margin-bottom: 0px;
		max-width: unset;
	}

	p {
		max-width: 45ch;
		color: var(--muted);
	}
}

//Testimonials
.testimonials {
	background-color: var(--black);
	color: var(--white);
	margin: 60px 0px;
}

.testimonial-info {
	display: flex;
	gap: 30px;
	align-items: center;

	img {
		width: 220px;
		height: 220px;
		border-radius: 12px;
	}

	>div h3 {
		font-size: 24px;
		margin-bottom: 20px;
	}

	>div p {
		font-size: 16px;
		color: var(--muted);
		max-width: 32ch;
	}


}

.testimonial-info:nth-child(odd) {
	margin-right: 20px;
}


.testimonials .slick-prev {
	left: 90%;
	top: -60px;
}

.testimonials .slick-next {
	top: -60px;
	right: 0;
}

.testimonials .slick-prev:before {
	content: "←";
	font-family: none;
	font-size: 28px;
}

.testimonials .slick-next:before {
	content: "→";
	font-family: none;
	font-size: 28px;
}


// offer popup

.offer-box {
	position: relative;
	width: 75%;
	margin: 0 auto;
	height: auto;
	margin-top: calc(100vh - 85vh - 20px);
	background: var(--white);
	border-radius: 10px;
	overflow: auto;

	>div {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 20px;
		justify-self: center;

		img {
			width: 100%;
			height: 100%;
		}

		.offer-info {
			text-align: center;
			height: fit-content;
			align-self: center;

			svg {
				float: right;
				margin-right: 60px;
				margin-top: -100px;
			}

			h2 {
				font-weight: 700;
			}

			span {
				display: block;
				background-color: #CBECF4;
				color: var(--primary);
				font-size: 20px;
				font-weight: 700;
				padding: 12px;
				border-radius: 6px;
				width: 250px;
				margin: 20px auto;
			}
		}
	}
}

.tabs-inputs {
	display: flex;
	gap: 20px;
	margin-top: 26px;
}

.tabs-desktop-view {
	display: block;
}

.tabs-mobile-view {
	display: none;
}


.index-filter-option-box {
	position: relative;
	width: 60%;
	margin: 0 auto;
	height: auto;
	max-height: 85vh;
	margin-top: calc(100vh - 90vh - 20px);
	background: var(--white);
	border-radius: 10px;
	overflow: auto;

	.filters {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 45px;
		align-items: center;
		padding: 45px;
		padding-top: 20px;
	}
}

.index-page-filter-options {
	background-color: var(--white);
	height: fit-content;
	width: 100%;
	box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
	border-radius: 12px;
	padding: 30px;
}

// Responsive


@media screen and (max-width: 440px) {
	.design-card-info {
		padding:8px
	}

	.design-card-info button {
		font-weight: 600 !important;
		margin-top: 14px;
		font-size: 10px;
	}

	.house-design-card-img {
		width: 100%;
		height: 165px;
	}

	.design-card-info h3 {
		font-size: 14px;
	}

	.design-card-options > div span {
		font-size: 8px;
		color: var(--muted);
	}
}
@media (max-width:1220px) {
	.index-filter-option-box {
		width: 90%;
	}

	.our-services {
		img {
			height: 280px;
		}
	}

	.hero-info {
		gap: 30px !important;
	}

	.hero-info h2 {
		font-size: 32px !important;
	}

	.hero-img img {
		height: 500px;
	}

	.tabs-inputs {
		flex-wrap: wrap;
	}

	.tabs-form-input,
	.tabs-form-select {
		width: 46% !important;
	}

	.tabs-inputs button {
		width: 46% !important;
	}
}


@media (min-width:1080px) and (max-width:10000px) {
	.house-slide-hide {
		display: none;
	}
}

@media (max-width:1080px) {
	.house-section-hide {
		display: none;
	}

	.house-slide-hide {
		display: block;
		margin-top: 30px;

		.slick-slide {
			width: 350px !important;
			margin-left: 20px;
		}
	}

	.services {
		grid-template-columns: repeat(2, 1fr);
		gap: 30px;
	}
	.overlay-image {
		img{
			width: 40vw;
    		height: 30vw;
		}
	}
	.hero-img {
		img {
			width: 600px;
		}
	}

	.hero-img img {
		height: auto;
	}

	.section-images {
		grid-template-columns: repeat(2, 1fr);
		gap: 40px;
	}

	.our-services-list {
		grid-template-columns: repeat(1, 1fr);

	}

	.our-services img {
		height: 300px;
	}

	.testimonial-info {
		img {
			width: 180px;
			height: 180px;
		}
	}

	.offer-box>div .offer-info svg {
		margin-top: -30px;
	}
}

@media (max-width:991px) {

	.index-filter-option-box {

		.filter-option-head,
		.filter-option-button {
			padding: 0px !important;
		}

		position: relative;
		width: 50%;
		margin: 0px !important;
		height: auto;
		max-height: 100% !important;
		margin-top: 0px !important;
		border-radius: 0 !important;
		float: right !important;

		.filters {
			display: grid;
			grid-template-columns: 1fr !important;
			column-gap: 45px;
			align-items: center;
			padding: 20px 0px;
		}
	}
	.overlay-image {
		img{
			width: 40vw;
    		height: 30vw;
		}
	}
	.offer-box {
		margin-top: calc(100vh - 75vh - 60px);
	}

	.design-card-options {
		gap: 10px;

		>div img {
			width: 12px;
			height: 12px;
		}
	}


	.hero-wrap {
		flex-wrap: wrap;
	}

	.hero-info {
		max-width: 50ch;
	}

	.hero-img {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		height: auto;

	}

	.our-services {
		grid-template-columns: repeat(1, 1fr);
	}

	.our-services img {
		height: 350px;
		order: 1;
		margin: 0 auto;
	}
}



@media (max-width:768px) {
	.index-filter-option-box {
		width: 80% !important;
	}

	.tabs-desktop-view {
		display: none;
	}

	.tabs-mobile-view {
		display: block;
	}

	.tabs-form-input,
	.tabs-form-select {
		width: 100% !important;
	}

	.tabs-inputs {
		gap: 10px !important;
		margin-top: 0px !important;
	}

	.tabs-inputs button {
		width: 100% !important;
		margin-top: 8px !important;
	}
	.overlay-image {
		img{
			width: 40vw;
    		height: 30vw;
		}
	}
	.offer-box {
		margin-top: calc(100vh - 90vh - 20px);
		width: 90%;

		>div {
			grid-template-columns: 1fr;

			img {
				height: 300px;
			}

			.offer-info {
				margin: 30px 0px;

				svg {
					margin-top: -30px;
				}
			}
		}
	}

	.site-wrapper {
		padding: 0px 20px;
	}



	.index-section {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.hero-wrap {
		padding-left: 20px;
	}

	.hero-section {
		padding-bottom: 0px;
	}

	.hero-img {
		img {
			width: 100%;
			height: auto;
			justify-self: flex-end;
			align-self: flex-end;
		}
	}

	.tabs-section {
		margin-top: 0px;
	}
}

@media (max-width: 580px) {

	.section-images {
		grid-template-columns: repeat(1, 1fr);
		gap: 40px;
	}

	.index-filter-option-box {
		width: 90% !important;

		.selects-info {
			justify-content: start !important;
			gap: 8px;
		}

		.product-tabs-select {
			width: auto !important;
		}
	}
	.overlay-image {
		img{
			width: 90vw;
   			height: 60vw;
		}
	}
	.tabs-info {
		padding: 18px;
	}

	.tabs-details {
		gap: 10px;

		li {
			border-radius: 6px;
			padding: 4px 10px;
			list-style: none;
			font-size: 14px;

			.filter-icon {
				margin-left: 0px;
				margin-right: 8px;
			}

			svg {
				margin-left: 8px;
				font-size: 14px;
			}
		}
	}

	.our-services img {
		height: 280px;
	}

	.testimonial-info {
		gap: 20px;

		img {
			width: 150px;
			height: 200px;
		}

		>div h3 {
			font-size: 20px;
			margin-bottom: 10px;
		}

		>div p {
			font-size: 14px;
		}
	}

	.testimonials .slick-prev {
		left: 80%;
		top: -90px;
	}

	.testimonials .slick-next {
		top: -90px;
	}

	.testimonail-title {
		max-width: 15ch !important;
	}
}

@media (max-width:480px) {
	.testimonial-info {
		flex-direction: column;
		align-items: flex-start;

		img {
			width: 200px;
			height: 200px;
		}

		>div p {
			max-width: 100%;
		}
	}

	.section-top h3 {
		font-size: 24px;
	}


	.tabs-form-input,
	.tabs-form-select {
		width: 100%;

	}

	.section-top p {
		max-width: 40ch;
	}

	.our-services img {
		width: 300px;
		height: 260px;
	}

	.hero-wrap {
		gap: 20px;
	}

	.hero-info {
		padding-right: 20px;

		h2 {
			font-size: 26px !important;
		}

		p {
			font-size: 13px;
			margin-top: 0px;
		}
	}

	.services-wrap {

		h3 {
			font-size: 18px;
		}

		p {
			font-size: 14px;
		}
	}

	.tabs-details {
		gap: 5px;

		li {
			border-radius: 6px;
			padding: 2px 6px;
			list-style: none;
			font-size: 12px;

			.filter-icon {
				margin-left: 0px;
				margin-right: 5px;
			}

			svg {
				margin-left: 5px;
				font-size: 10px;
			}
		}
	}


	
}

@media screen and (max-width: 350px) {
	.house-design-card {
		position: relative;
		top: 60px;
		right: -28px;
		width: 70vw !important
	}

	.house-design-card img  {
		width: 100%;
		height: 145px;
	}
}

@media screen and (min-width:350px) and (max-width: 414px) {
	.house-design-card {
		position: relative;
		top: 70px;
		right: -45px;
		width: 70vw !important;
		height: 295px;
	}

	.house-design-card img  {
		width: 100%;
		height: 145px;
	}
}

@media screen and (min-width: 420px) and (max-width: 580px) {
	.house-design-card {
		position: relative;
		right: -82px;
    	// width: 72vw !important;
	}
	.design-card-options > div span {
		font-size: 8px;
	}

	.design-card-info button {
		margin-top: 15px;
	}
}
@media screen and (min-width: 580px) and (max-width: 768px) {
	.house-design-card {
		position: relative;
		right: 60px;
		width: 44vw !important
	}

	.design-card-info button {
		margin-top: 15px;
	}

	.design-card-options > div span {
		font-size: 10px;
	}

}

@media screen and (min-width: 768px) and (max-width: 820px) {
	.house-design-card {
		position: relative;
		right: 60px;
		width: 44vw !important
	}

	.design-card-info button {
		margin-top: 15px;
	}

	.design-card-options > div span {
		font-size: 10px;
	}
}

@media screen and (min-width: 860px) and (max-width: 1020px) {
	.house-design-card {
		position: relative;
		right: -4px;
    	width: 348px !important;
	}
}
@media screen and (min-width: 1024px) and (max-width: 1280px) {
	.house-design-card {
		position: relative;
		// right: 105px;
		//width: 38vw !important
	}
}

@media (max-width: 986px) {


	.services {
		grid-template-columns: repeat(1, 1fr);
		gap: 0px;

		.services-wrap {
			width: 100%;
		}
	}

}

@media (max-width:360px) {
	.section-top h3 {
		font-size: 20px;
	}

	.offer-box {
		margin-top: calc(100vh - 90vh - 20px);

		>div {

			img {
				height: 300px;
			}

			.offer-info {
				margin: 40px 0px;
				h2 {
					font-size: 18px !important;
					margin-top: 12px !important;
				}
				h3 {
					font-size: 18px !important;
				}
				span{
					font-size: 14px !important;
				}
				.button{
					font-size: 14px !important;
				}
			}
		}
	}

}
.no-data-text {
	font-size: 18px;
    font-weight: 500;
    margin: 20px;
}

.testimonials{
	.site-wrapper{
		.section-top{
			margin-bottom: 70px;
			padding-top: 50px;
		}
	}
}