/* General styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;900&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --black: #0C0B0B;
  --white: #ffffff;
  --muted: #999999;
  --primary: #0A82DF;
  --secondary: #05E7B9;
}

a {
  text-decoration: none;
  color: var(--primary);
}

.button {
  border: none;
  background-image: linear-gradient(to right, var(--primary), var(--secondary), );
  color: var(--white);
  padding: 6px 20px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 700;


  a {
    color: var(--white);
    text-decoration: none;
  }
}

// Index or Homepage
@import url(./components-styles/index.scss);

// Product page and Product Details Page
@import url(./components-styles/product.scss);

// Order Summary
@import url(./components-styles/order-summery.scss);

// My Designs, My Favourites and My Design Details
@import url(./components-styles/my-designs.scss);

// Faq
@import url(./components-styles/faq.scss);

// Help
@import url(./components-styles/help.scss);

// About and Contact
@import url(./components-styles/about-and-contact.scss);

// Header
@import url(./components-styles/header.scss);

// Footer
@import url(./components-styles/footer.scss);

// Login and Signup
@import url(./components-styles/login-and-signup.scss);

// Profile
@import url(./components-styles/profile.scss);

// Payments
@import url(./components-styles/payments.scss);

// Comman Code

.page-head {
  text-align: center;
  margin: 70px 0px;

  h2 {
    font-weight: 600;
    margin: 12px 0px;
  }

  p {
    color: var(--muted);
    font-size: 20px;
  }

  .page-search {
    width: 330px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 4px;
    margin-top: 30px;
    border: 2px solid #e2e2e2;

    span {
      background: transparent;
      border: none;
      color: var(--muted);
      padding-right: 0px;
    }

    input {
      background: transparent;
      color: var(--muted);
      border: none;

      &:focus {
        border: none !important;
        box-shadow: none !important;
        background: transparent;

      }
    }
  }
}



/*======================
    404 page
=======================*/


.page_404 {
  padding: 0px 0px 0px 0px;
  background: #fff;
  font-family: 'Arvo', serif;
}

.contant_box_404 .whoops {
  color: #02675f;
  font-size: 50px;
  font-weight: 800;
  letter-spacing: 2px;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.contant_box_404 .something_went {
  color: #02675f;
  font-size: 50px;
  font-weight: 800;
  letter-spacing: 2px;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.contant_box_404 .h2 {
  color: #02675f;
  font-size: 50px;
  font-weight: 800;
  letter-spacing: 2px;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
}

.contant_box_404 .cant_find {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
}

// .four_zero_four_bg {
//   height: 300px;
//   background-position: center;
//   // margin-top: 90px;
//   position: relative;
// }

.logo_img img {
  height: 70px;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  font-size: 14px;
  padding: 10px 32px;
  background: #0888dd;
  /* margin: 20px 0; */
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 800;
  font-family: 'Open Sans', sans-serif;
}

.contant_box_404 {
  position: absolute;
  left: 8%;
  /* bottom: 30px; */
  top: 40%;
}

.notification_clearall {
  display: flex !important;
}

.notification-card .card-body {
  padding: 10px 10px 10px 10px;
}

.card-body table {
  width: 100%;
}

.card-body table tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-body table tr td button.btn {
  font-size: 0.8rem;
  padding: 3px;
  margin: 6px 6px 6px 6px;
}

.card-body table tr td:nth-child(2) {
  text-align: right;
  justify-content: space-around;
}

.card-title:before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1.1rem;
  text-align: center;
  border: 2px solid grey;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  padding-bottom: 3px;
  margin-right: 10px;
}

.card-notification-main {
  display: grid;
}

.card-notification-main .card-title {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.card-notification-main .card-desc {
  color: #000;
  font-size: 14px;
}

.card.display-none {
  display: none;
  transition: opacity 2s;
}

.dismiss {
  text-align: end;
}

.dismiss-all {
  background: transparent;
  color: #0A82DF;
  border: 0;
}

.dismiss-all:hover {
  background: transparent;
  color: #0A82DF;
  border: 0;
}

.dismiss-all:active {
  background: transparent;
  color: #0A82DF;
  border: 0;
}

.dismiss-all:focus {
  background: transparent;
  color: #0A82DF;
  border: 0;
  box-shadow: none !important;
}

.notification-card {
  margin: 20px 0px 20px 0px;
}

.notification-view {
  align-items: top;
  gap: 15px;
  border-top: 1px solid #eee;
  text-align: left;
  padding: 15px 0px;
}

.view-notifi {
  color: #000;
  font-size: 16px;
  text-align: center;
}

.notification_read {
  font-size: 14px;
  color: #64686a;
  margin-bottom: 0;
  font-weight: 700;
}

.notification_unread {
  font-size: 14px;
  color: #000;
  margin-bottom: 0;
  font-weight: 700;
}

.error-field {
  border-color: crimson !important;
}

/* responsive*/

@media screen and (max-width: 375px) {
  .contant_box_404 {
    position: absolute;
    left: 10%;
    /* bottom: 30px; */
    top: 48%;
    width: 80%;
  }

  .link_404 {
    color: #fff !important;
    font-size: 10px;
    padding: 10px 32px;
    background: #0888dd;
    /* margin: 20px 0; */
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 800;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .cant_find {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .whoops {
    color: #02675f;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 2px;
    width: 100%;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .something_went {
    color: #02675f;
    font-size: 28px;
    font-weight: 700;
    width: 100%;
    letter-spacing: 2px;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .h2 {
    color: #02675f;
    font-size: 28px;
    font-weight: 700;
    width: 100%;
    letter-spacing: 2px;
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
  }

}

@media screen and (max-width: 420px) {
  .contant_box_404 {
    position: absolute;
    left: 10%;
    /* bottom: 30px; */
    top: 40%;
    width: 80%;
  }

  .link_404 {
    color: #fff !important;
    font-size: 10px;
    padding: 10px 32px;
    background: #0888dd;
    /* margin: 20px 0; */
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 800;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .cant_find {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .whoops {
    color: #02675f;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 2px;
    width: 100%;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .something_went {
    color: #02675f;
    font-size: 28px;
    font-weight: 700;
    width: 100%;
    letter-spacing: 2px;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .h2 {
    color: #02675f;
    font-size: 28px;
    font-weight: 700;
    width: 100%;
    letter-spacing: 2px;
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
  }
}

@media screen and (min-width:425px) and (max-width: 550px) {
  .contant_box_404 {
    position: absolute;
    left: 16%;
    /* bottom: 30px; */
    top: 46%;
    //width: 80%;
  }

  .link_404 {
    color: #fff !important;
    font-size: 12px;
    padding: 10px 32px;
    background: #0888dd;
    /* margin: 20px 0; */
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 800;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .cant_find {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .whoops {
    color: #02675f;
    font-size: 30px;
    font-weight: 800;
    letter-spacing: 2px;
    //width: 100%;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .something_went {
    color: #02675f;
    font-size: 30px;
    font-weight: 800;
    //width: 100%;
    letter-spacing: 2px;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .h2 {
    color: #02675f;
    font-size: 30px;
    font-weight: 800;
    //width: 100%;
    letter-spacing: 2px;
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
  }
}

@media screen and (min-width:550px) and (max-width:920px) {
  .contant_box_404 {
    position: absolute;
    left: 5%;
    /* bottom: 30px; */
    top: 14%;
    //width: 80%;
  }

  .link_404 {
    color: #fff !important;
    font-size: 12px;
    padding: 10px 32px;
    background: #0888dd;
    /* margin: 20px 0; */
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 800;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .cant_find {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .whoops {
    color: #02675f;
    font-size: 30px;
    font-weight: 800;
    letter-spacing: 2px;
    //width: 100%;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .something_went {
    color: #02675f;
    font-size: 30px;
    font-weight: 800;
    //width: 100%;
    letter-spacing: 2px;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }

  .contant_box_404 .h2 {
    color: #02675f;
    font-size: 30px;
    font-weight: 800;
    //width: 100%;
    letter-spacing: 2px;
    margin-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
  }
}

.help_icon_wrapper {
  border: 1px solid var(--muted);
  border-radius: 12px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .help_icon {
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }
}

.design_plan_modal {
  .btn-close {
    &:focus {
      box-shadow: none;
    }
  }
}